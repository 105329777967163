import '../styles/globals.css';

import {QueryClient, QueryClientProvider} from 'react-query';

import {SessionProvider} from '../services/supabase';
const queryClient = new QueryClient();

function MyApp({Component, pageProps}) {
  console.log('hola');
  return (
    <SessionProvider>
      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} />
      </QueryClientProvider>
    </SessionProvider>
  );
}

export default MyApp;
