import {AuthChangeEvent, createClient, Session} from '@supabase/supabase-js';
import React, {useContext} from 'react';
import Router from 'next/router';
import {debounce} from 'lodash';

const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL;
const supabaseAnonKey = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// React Context
const SessionContext = React.createContext<Session | null | undefined>(undefined);

const routeDict: Record<AuthChangeEvent, string | undefined> = {
  PASSWORD_RECOVERY: '/auth/password-recovery',
  SIGNED_IN: '/welcome',
  SIGNED_OUT: '/',
  TOKEN_REFRESHED: undefined,
  USER_UPDATED: undefined,
  USER_DELETED: undefined,
};

export const SessionProvider = (props: {children: React.ReactNode}) => {
  const [session, setSession] = React.useState<Session | null>(null);
  React.useEffect(() => {
    console.log('updated session', session);
  }, [session]);

  React.useEffect(() => {
    setSession(supabase.auth.session());

    const handleAuthRerouting = debounce(
      (_event: AuthChangeEvent) => {
        const routePath = routeDict[_event];
        if (!routePath) {
          return;
        }

        Router.push(routePath);
      },
      300,
      {leading: false, trailing: true},
    );

    supabase.auth.onAuthStateChange((_event, session) => {
      console.log('onAuthStateChange', _event);
      setSession(session);
      handleAuthRerouting(_event);
    });
  }, []);

  return <SessionContext.Provider value={session}>{props.children}</SessionContext.Provider>;
};

export const useSession = () => {
  const session = useContext(SessionContext);
  if (typeof session === 'undefined') {
    throw new Error('useSession has to be used within SessionProvider context');
  }

  return session;
};
